import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
/* import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; */
/* import 'bootstrap/dist/css/bootstrap.min.css'; */
/* import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; */


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

export default App;
