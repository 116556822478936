
  export const rhondaPortfolio = [
    {
      id: 1,
      title: "Rhonda",
      img: "assets.nosync/rhonda.jpg",
    },
    {
      id: 2,
      title: "Cool Bean",
      img: "assets.nosync/coolbean.png",
    },
    {
      id: 3,
      title: "Bean",
      img: "assets.nosync/cutebean.jpg",
    },
    {
      id: 4,
      title: "Bean",
      img: "assets.nosync/bean.jpg",
    },
    {
      id: 5,
      title: "Goofy Bean",
      img: "assets.nosync/goofybean.PNG",
    },
    {
      id: 6,
      title: "Sleepy Bean",
      img: "assets.nosync/sleepybean.png",
    },
  ];
  
  export const philosophyPortfolio = [
    {
      id: 1,
      title: "Marcus Aurelius",
      img:
        "assets.nosync/action.jpg",
    },
    {
      id: 2,
      title: "Seneca",
      img:
        "assets.nosync/onanger.jpg",
    },
    {
      id: 3,
      title: "James A. Michener",
      img:
        "assets.nosync/themaster.jpg",
    },
    
    {
      id: 4,
      title: "Epictetus",
      img:
        "assets.nosync/tolearn.jpg",
    },
    {
      id: 5,
      title: "Marcus Aurelius",
      img:
        "assets.nosync/harmedbyfear.jpg",
    },
    {
      id: 6,
      title: "Marcus Aurelius",
      img:
        "assets.nosync/closerto.jpg",
    },
    
  ];
  
  export const readingPortfolio = [
    {
      id: 1,
      title: "The Obstacle is the Way",
      img:
        "assets.nosync/obstacle.png",
      link: "https://www.amazon.com/Obstacle-Way-Timeless-Turning-Triumph/dp/1591846358/ref=sxts_rp_s1_0?cv_ct_cx=the+obstacle+is+the+way&dchild=1&keywords=the+obstacle+is+the+way&pd_rd_i=1591846358&pd_rd_r=8ffd9557-6ac6-4f1f-9d49-964bd7ec2333&pd_rd_w=nzmaE&pd_rd_wg=XadKX&pf_rd_p=60f680d5-2ef4-438b-a860-68ff8dd70684&pf_rd_r=3EAWWM0G9CR2RCFGFWW9&psc=1&qid=1633527962&sr=1-1-2a7ba1aa-1291-4447-a5e4-5bbe384024ef"
    },
    {
      id: 2,
      title: "Breaking the Habit of Being Yourself",
      img:
        "assets.nosync/breakinghabit.jpg",
      link: "https://www.amazon.com/Breaking-Habit-Being-Yourself-Create/dp/1401938094/ref=sr_1_1?crid=324T2LK5BCBI7&dchild=1&keywords=breaking+the+habit+of+being+yourself&qid=1633528001&s=books&sprefix=breaking+the+habit+of+b%2Cstripbooks%2C164&sr=1-1"
    },
    {
      id: 3,
      title: "Emotional Intelligence: Authentic Leadership",
      img:
        "assets.nosync/authentic.jpg",
      link: "https://www.amazon.com/Authentic-Leadership-HBR-Emotional-Intelligence/dp/1633693910/ref=sr_1_3?crid=2QNJWZHX1YGLQ&dchild=1&keywords=emotional+intelligence+authentic+leadership&qid=1633528038&sprefix=emotional+intelligence+authentic+%2Cstripbooks%2C169&sr=8-3"
    },
    {
      id: 4,
      title: "Permission to Screw Up",
      img:
        "assets.nosync/permission.jpg",
      link:"https://www.amazon.com/Permission-Screw-Up-Learned-Everything/dp/1591848296/ref=sr_1_1?dchild=1&keywords=permission+to+screw+up&qid=1633528073&sr=8-1"
    },
    {
      id: 5,
      title: "Seat of the Soul",
      img:
        "assets.nosync/seatofsoul.jpg",
        link: "https://www.amazon.com/Seat-Soul-Anniversary-Study-Guide/dp/147675540X/ref=sr_1_1?dchild=1&keywords=seat+of+the+soul&qid=1633528092&sr=8-1"
    },
    {
      id: 6,
      title: "Stillness is the Key",
      img:
        "assets.nosync/stillness.jpg",
      link: "https://www.amazon.com/Stillness-Key-Ryan-Holiday/dp/0525538585/ref=sxts_rp_s1_0?crid=3FBZSTEQWH1IG&cv_ct_cx=stillness+is+the+key&dchild=1&keywords=stillness+is+the+key&pd_rd_i=0525538585&pd_rd_r=3d685a00-7fe6-4ee4-84ad-38d4c83fa8e0&pd_rd_w=m0KbA&pd_rd_wg=FsU4R&pf_rd_p=60f680d5-2ef4-438b-a860-68ff8dd70684&pf_rd_r=XPRBGG4ZQHDF48CDBFBT&psc=1&qid=1633528113&sprefix=stillness+is+th%2Caps%2C174&sr=1-1-2a7ba1aa-1291-4447-a5e4-5bbe384024ef"
    },
    {
        id: 7,
        title: "Ego is the Enemy",
        img:
          "assets.nosync/ego.jpg",
        link: "https://www.amazon.com/Ego-Enemy-Ryan-Holiday/dp/1591847818/ref=sr_1_1?crid=5OOUSC7KRYYQ&dchild=1&keywords=ego+is+the+enemy&qid=1633528143&s=books&sprefix=ego+is+the+e%2Cstripbooks%2C173&sr=1-1"
      },
      {
        id: 8,
        title: "Warlight",
        img:
          "assets.nosync/warlight.jpg",
        link: "https://www.amazon.com/Warlight-Vintage-International-Michael-Ondaatje/dp/0525562966/ref=sr_1_1?dchild=1&keywords=warlight&qid=1633528165&s=books&sr=1-1"
      },
      {
        id: 9,
        title: "Atomic Habits",
        img:
          "assets.nosync/atomic.jpg",
        link: "https://www.amazon.com/Atomic-Habits-Proven-Build-Break/dp/0735211299/ref=sr_1_1?crid=1FZFTJWUZNZ6O&dchild=1&keywords=atomic+habits&qid=1633528189&s=books&sprefix=atomic%2Cstripbooks%2C188&sr=1-1"
      },
      {
        id: 10,
        title: "Meditations",
        img:
          "assets.nosync/meditations.jpg",
        link: "https://www.amazon.com/Meditations-150th-Anniversary-Collection-Philosophy/dp/B097C299F4/ref=sr_1_4?dchild=1&keywords=meditations&qid=1633528229&s=books&sr=1-4"
      },
      {
        id: 11,
        title: "The Daily Stoic",
        img:
          "assets.nosync/dailystoic.jpg",
        link: "https://www.amazon.com/Daily-Stoic-Meditations-Wisdom-Perseverance/dp/0735211736/ref=sr_1_1?crid=1LWM066BK4133&dchild=1&keywords=the+daily+stoic&qid=1633528289&s=books&sprefix=the+daily%2Cstripbooks%2C180&sr=1-1"
      },
      {
        id: 12,
        title: "Courage is Calling",
        img:
          "assets.nosync/courage.jpg",
        link: "https://www.amazon.com/Courage-Calling-Fortune-Favors-Brave/dp/0593191676/ref=sr_1_2?crid=Z9ZTM9P9P01A&dchild=1&keywords=courage+is+calling+ryan+holiday&qid=1633528307&s=books&sprefix=courage+is+call%2Cstripbooks%2C228&sr=1-2"
      },
  ];
  
  export const podcastPortfolio = [
    {
      id: 1,
      title: "Huberman Lab",
      img:
        "assets.nosync/huberman.png",
        link: "https://hubermanlab.com",
    },
    {
      id: 2,
      title: "Impact Theory",
      img:
        "assets.nosync/bilyue.png",
        link: "https://impacttheory.com",
    },
    {
      id: 3,
      title: "Stuff To Blow Your Mind",
      img:
        "assets.nosync/stuffto.png",
        link: "https://www.iheart.com/podcast/stuff-to-blow-your-mind-21123915/",
    },
    {
      id: 4,
      title: "Lex Fridman Podcast",
      img:
        "assets.nosync/lex.png",
        link: "https://lexfridman.com/podcast/",
    },
    {
      id: 5,
      title: "The Ed Mylett Show",
      img:
        "assets.nosync/mylett.png",
        link: "https://www.edmylett.com/podcasts/",
    },
   
  ];

  export const portfolioList = [
    {
      id:1,
      title: "React",
      img:
          "assets.nosync/react.png",
      link: "https://github.com/shayleemeyer/nucampReact"
    },
    {
      id:2,
      title: "React Native",
      img:
          "assets.nosync/reactNative.png",
      link: "https://github.com/shayleemeyer/ReactNative"
    },
    {
      id:3,
      title: "Bootstrap",
      img:
          "assets.nosync/bootstrap.png",
      link: "https://github.com/shayleemeyer/nucampsiteBootstrap"
    },
    {
      id:4,
      title: "React",
      img:
          "assets.nosync/centerutilization.png",
      link: "https://github.com/shayleemeyer/centerutilization1"
    },
    {
      id:5,
      title: "Node",
      img:
          "assets.nosync/node.png",
      link: "https://github.com/shayleemeyer/nucampsiteServer"
    },
    {
      id:6,
      title: "eCommerce Site (coming soon)",
      img:
          "assets.nosync/reactecom.jpg",
      link: "link"
    }
  ]

  export const iconList = [
    {
      id:1,
      title: "React",
      img:
          "assets.nosync/reacticon.png",
      link: "https://reactjs.org"
    },
    {
      id:1,
      title: "Node",
      img:
          "assets.nosync/nodejsicon.png",
      link: "https://nodejs.org/en/"
    },
    {
      id:1,
      title: "MongoDb",
      img:
          "assets.nosync/mongodbicon.png",
      link: "https://www.mongodb.com"
    }, 
    {
      id:1,
      title: "Redux",
      img:
          "assets.nosync/reduxicon.png",
      link: "https://react-redux.js.org"
    },
    {
      id:1,
      title: "Bootstrap",
      img:
          "assets.nosync/bootstrapicon.png",
      link: "https://getbootstrap.com"
    },
    {
      id:1,
      title: "SASS",
      img:
          "assets.nosync/sassicon.png",
      link: "https://sass-lang.com"
    },
    
    {
      id:1,
      title: "HTML5",
      img:
          "assets.nosync/html5.png",
      link: "https://html.com/html5/"
    },
    {
      id:1,
      title: "AWS",
      img:
          "assets.nosync/awsicon.png",
      link: "https://aws.amazon.com/?nc2=h_lg"
    },
    
    
    {
      id:1,
      title: "Github",
      img:
          "assets.nosync/githubicon.png",
      link: "https://github.com"
    },
    
    {
      id:1,
      title: "NPM",
      img:
          "assets.nosync/npmicon.png",
      link: "https://www.npmjs.com"
    },
    
    {
      id:1,
      title: "Yarn",
      img:
          "assets.nosync/yarnicon.png",
      link: "https://yarnpkg.com"
    },
    
    {
      id:1,
      title: "Java",
      img:
          "assets.nosync/javaicon.png",
      link: "https://www.java.com/en/"
    },


  ]

  